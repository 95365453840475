import React, {Component} from 'react';
import fetch from 'isomorphic-fetch';
import ItemCarrinho from './ItemCarrinho';
import CriteoCarrinho from './CriteoCarrinho';
import {ConsumerCarrinho} from '../ContextLojamundi.js';

class Carrinho extends Component {
    constructor(props){
        super(props)
        this.state = {
            itens:[],
            qtyTotal:0,
            valorTotal:0
        }
    }
    render() {
        return (
            <>
            <ConsumerCarrinho>
                {value => 
                <React.Fragment>
                    <button onClick={e => {value.tbProdutos.forEach((item, index) => item.qty == 0 && (value.removeItem(index)))}} type="button" className="btn btn-danger btn-sm ml-1" data-toggle="modal" data-target="#exampleModal" ref={value.ModalCarrinho}>
                        <span className="d-none d-lg-block"><i className="fa fa-shopping-cart" aria-hidden="true"></i> Carrinho <span className="badge badge-light">{value.qty}</span></span>
                        <span className="d-lg-none"><i className="fa fa-shopping-cart" aria-hidden="true"></i> <span className="badge badge-light">{value.qty}</span></span>
                    </button>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-danger">
                                    <h3 className="modal-title tituloCarrinhoComprasModal" id="exampleModalLabel"><i className="fa fa-shopping-cart" aria-hidden="true"></i> Carrinho de Compras</h3>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {value.tbProdutos.map((item, index) =>
                                    <ItemCarrinho 
                                        produto={item} 
                                        key={index} 
                                        index={index} 
                                        removeItem={value.removeItem} 
                                        updateItem={value.updateItem}/>
                                    )}
                                </div>
                                <div className="text-center mb-3 marginBottomNegativo"><h3>Total: R$ {value.tbProdutos.reduce((soma, item) => soma + item.total,0).toLocaleString('pt-BR',{minimumFractionDigits:2, maximumFractionDigits:2})}</h3> </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-sm btn-secondary" data-dismiss="modal">Continuar</button>
                                    <a onClick={e => {value.tbProdutos.forEach((item, index) => item.qty == 0 && (value.removeItem(index)))}} href={value.tbProdutos.length ? "/checkout" : "/"}><button id="btFinalPedido" type="button" className="btn btn-sm btn-danger">Finalizar Pedido</button></a>
                                    <button onClick={e => value.removeAll()} type="button" className="btn btn-sm btn-secondary" data-dismiss="modal">Limpar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                }
            </ConsumerCarrinho>
            <CriteoCarrinho/>
    </>
            );
}
}

export default Carrinho;
