import React, { Component } from 'react';

class Box0800PC extends Component {
   constructor(props){
      super(props)
      this.state = {
      }
   }
   render(){
      return (
         <div className="card">
            <div className="card-body">
               <div className="text-center" style={{"font-size": "24px", "color":"#3a3939", "font-weight": "bold"}}>Contatos</div>
               <div className="m-auto" style={{"background-color":"#dc3545", "height": "2px", "width": "49px"}}></div>
               <a href='https://web.whatsapp.com/send?phone=5561991375620&amp;text=Ol%c3%a1%2c%20gostaria%20de%20saber%20mais%20informa%c3%a7%c3%b5es%20sobre%20seus%20produtos.' id='facebook-btn' target='_blank'>
                  <div className="text-center mt-3" style={{"font-size": "18px"}}><span style={{"color":"#96ca3c"}}><i className="fab fa-whatsapp"></i></span> 61 99137 5620</div>
                  <style jsx>{`
                                       a:link {
                                       color: #3a3939;
                                       text-decoration:none;
                                       }

                                           a:visited {
                                           color: #3a3939;
                                           }

                                       a:hover {
                                       color: #49ab07;
                                       }

                                       a:active {
                                       color: #3a3939;
                                       }
                                       `}</style>
               </a>
               <div><hr/></div>
               <div className="text-center" style={{"font-size": "18px"}}><span style={{"color":"#3a3939"}}><i className="fas fa-headphones-alt"></i></span> 11 2666 4242</div>
               <div className="text-center" style={{"font-size": "18px"}}><span style={{"color":"#3a3939"}}><i className="fas fa-headphones-alt"></i></span> 21 2169 8855</div>
               <div className="text-center" style={{"font-size": "18px"}}><span style={{"color":"#3a3939"}}><i className="fas fa-headphones-alt"></i></span> 31 4042 1799</div>
               <a id="outrosTelefones" href="/outrosTelefones">
                  <div className="my-3 text-center">
                     <button type="button" className="btn bg-danger text-white">Outros Telefones</button>
                  </div>
               </a>
            </div>
         </div>


      )
   }
}
export default Box0800PC;
