import React, { Component } from 'react';

class AoVivoCat extends Component {
   constructor(props) {
      super(props)
      this.state = {
      }
   }
   render() {
      return (
         <div className="mt-3 mb-2 container">
            <picture className='mr-2'>
               <source type="image/webp" srcSet="/imagens/FabricioGif-Categorias.webp" />
               <img src="/imagens/FabricioGif-Categorias.png" loading="lazy" style={{ width: '10%' }} />
            </picture>
            &nbsp;
            {/*<picture>
               <source type="image/webp" srcSet="/imagens/CamilaGif-Categorias.webp"/>
               <img src="/imagens/CamilaGif-Categorias.png" loading="lazy" style={{width: '10%'}}/> 
            </picture>
      &nbsp;*/}
            <picture className='mr-2'>
               <source type="image/webp" srcSet="/imagens/DaviGif-Categorias.webp" />
               <img src="/imagens/DaviGif-Categorias.png" loading="lazy" style={{ width: '10%' }} />
            </picture>
            &nbsp;
            <picture className='mr-2'>
               <source type="image/webp" srcSet="/imagens/JakelineGif-Categorias.webp" />
               <img src="/imagens/JakelineGif-Categorias.png" loading="lazy" style={{ width: '10%' }} />
            </picture>
            &nbsp;
            <picture className='mr-2'>
               <source type="image/webp" srcSet="/imagens/MoisesGif-Categorias.webp" />
               <img src="/imagens/MoisesGif-Categorias.png" loading="lazy" style={{ width: '10%' }} />
            </picture>
            &nbsp;<picture className='mr-4'>
               <source type="image/webp" srcSet="/imagens/circuloCategoriasArthur.webp" />
               <img src="/imagens/circuloGrandeArthur.png" loading="lazy" style={{ width: '10%' }} />
            </picture>
            &nbsp;
            <a href="/sala-comercial" target="_blank">
               <img src="/imagens/faleAoVivoCat.png" loading="lazy" />
            </a>
         </div>
      )
   }
}

export default AoVivoCat;
