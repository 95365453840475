import React, { Component } from 'react';
import {HOST} from '../../host.js'

const VideoMinutoLojamundi = ({video, index}) => (
   <div className="col-4 marginTop">
      <span>
         <img className="img img-responsive img-thumbnail" src={`data:image/png;base64,${video.minuto_image_minuto}`}  alt="{video.minuto_titulo}" loading="lazy"/>
      </span>
      <p>{video.minuto_titulo}</p>
      <button type="button" className="btn btn-outline-light btn-sm" data-toggle="modal" data-target={`#videoMinuto${index}`}>
         <i className="fa fa-video-camera" aria-hidden="true"></i> ver video
      </button>
      <div tabIndex="-1" role="dialog" id={`videoMinuto${index}`} aria-labelledby={`videoMinuto${index}`} aria-hidden="true" className="modal fade">
         <div className="modal-dialog" role="document">
            <div className="modal-content">
               <div className="modal-header bg-danger">
                  <h3 className="modal-title" id={`exampleModalLabel${index}`}><i className="fa fa-video-camera" aria-hidden="true"></i> Minuto Lojamundi</h3>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div className="modal-body">
                  <div className="text-dark">
                     <div className="embed-responsive embed-responsive-16by9">
                        <iframe allowFullScreen={true} className="embed-responsive-item" src={`https://www.youtube.com/embed/${video.minuto_url_publica}`} loading="lazy"></iframe>
                     </div>
                  </div>
               </div>
               <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Fechar</button>
                  <button type="button" className="btn btn-danger"><a className="linkTodosVideos" href="https://www.youtube.com/playlist?list=PLNFcMYOMzaGAaQkjPzA_e81_sCNkwQtnq" target="_blank">Todos os Vídeos</a></button>
               </div>
            </div>
         </div>
      </div>
   </div>

);


class MinutoLojamundi extends Component {
   constructor(props){
      super(props)
      this.state = {
         videosList:[]
      }
   }
   componentWillMount = async() => {
      let rt = await fetch(`${HOST}/api2/minuto`)
      rt = await rt.json()
      this.setState({videosList:rt})

   }
   render(){
      return (
         <div style={{"backgroundImage":"url(/imagens/bgMinutoLojamundi.jpg)","backgroundColor":"#f00001","backgroundRepeat":"no-repeat","backgroundPosition":"top"}} className="pb-5">
            <div className="container">
               <div className="row text-center">
                  { this.state.videosList.map((item, index) => 
                  <VideoMinutoLojamundi key={index} video={item} index={index}/>
                  )}
                  <div className="text-center m-auto">
                     <button type="button" className="btn btn-outline-dark my-5">
                        <a className="linkTodosVideos" href="https://www.youtube.com/playlist?list=PLNFcMYOMzaGAaQkjPzA_e81_sCNkwQtnq" target="_blank">Todos os Vídeos</a>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      )
   }
}

export default MinutoLojamundi;
