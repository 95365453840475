import React, { Component } from 'react';
class BannerFooter extends Component {
    constructor(props){
        super(props)
        this.state = {
            valor:''
        }
    }
    render(){
        return (
            <section>
                <a href="https://www.lojamundi.com.br/fanvil-x1s-telefone-ip">
                    <div style={{"backgroundImage":"url(/imagens/FanvilbannerFooter.png)","WebkitBackgroundSize": "cover","MozBackgroundSize":"cover","MsBackgroundSize":"cover","OBackgroundSize":"cover", "backgroundPosition":"center","backgroundSize":"cover","backgroundRepeat":"no-repeat","width":"100%","height":"499px"}}>
                        <div className="container">
                            <div className="row pt-5">
                                <div className="ml-auto mt-5 ">
                                    <img src="/imagens/textoFanvilBannerFooter.png"  loading="lazy"/><br/>
                                    <div className="text-center"> <button href="https://www.lojamundi.com.br/fanvil-x1s-telefone-ip" role="button" className="btn btn-outline-danger btn-lg mt-4 text-center">Quero Conhecer</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </section>

            )
    }
}

export default BannerFooter;
