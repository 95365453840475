import React, {Component} from 'react';
import fetch from 'isomorphic-fetch';
import {ContCarrinho} from '../ContextLojamundi';
import {
    addMaskCel,
    removeMaskNumber} from '../../funcoes'
import {HOST} from '../../host.js'

class TeLigamos extends Component {
    constructor(props){
        super(props)
        this.state = {
            nome:'',
            cel:'',
            hiddenButton:false
        }
    }
    static contextType = ContCarrinho
    getDataInput = (e) => {
        this.setState({[e.target.name]:e.target.value})
    }

    limpaNome = (e) => {
        let nome = e.target.value;
        if(/[^a-zA-Z ]/.test(nome)) return;
        let state = {...this.state}
        this.setState({nome})

    };

    ligando = async (e) => {
        if(this.state.cel.length < 11) {
            this.setState({cel:''})
            return
        }
        this.setState({hiddenButton:true})
        this.videoMomento.src = "/imagens/camilaLigando.gif";
        e.preventDefault()
        let products = this.context.tbProdutos.length ? JSON.stringify(this.context.tbProdutos) : ''
        let url = `${HOST}/api2/call?name=${this.state.nome}&cel=${this.state.cel}&page=Te-Ligamos&produtos=${products}`
        await fetch(url)
        this.videoMomento.src = "imagens/camilaDigiteAqui.gif";
        this.setState({hiddenButton:false})
        this.inputElement.click();
    };

    loadGif = async () => {
        this.videoMomento.src = "imagens/camilaDigiteAqui.gif";
        this.videoMomentoHidden.src = "imagens/camilaLigando.gif";
    };

    render() {
        return (
            <>
            <button ref={this.context.ModalTeLigamos} onClick={this.loadGif} type="button" className="btn btn-warning btn-sm" data-toggle="modal" data-target="#modalTeLigamos">
                <i className="fa fa-phone" aria-hidden="true"></i> Nós Te Ligamos
            </button>
            <div className="modal fade text-left" id="modalTeLigamos" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{maxWidth: '525px', margin: '1.75rem auto'}}>
                    <div className="modal-content">
                        <div className="modal-header bg-warning">
                            <h3 className="modal-title" id="exampleModalLabel">Nós Te Ligamos</h3>     
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form id="formTeLigamos">
                            <div className="modal-body">
                                <div>Olá, para sua maior comodidade, retornaremos para você. Basta preencher os campos abaixo com seu nome e telefone e clicar no botão "Ligar."
                                </div><p/>
                                <div className="row">
                                    <div className="col">
                                        <input onChange={this.limpaNome} type="text" maxLength="30" value={this.state.nome} className="form-control" placeholder="Seu Nome" required="required"/>
                                    </div>
                                    <div className="col">
                                        <input name="cel" onChange={e => this.getDataInput(removeMaskNumber(e))} maxLength="14" type="text" value={addMaskCel(this.state.cel)} className="form-control" placeholder="DDD sem 0+Telefone" required="required"/>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footerr">

                                <div className="row">
                                    <div className="col-lg-4 text-left ml-2">
                                        {
                                        this.state.hiddenButton || (
                                        <input onClick={this.ligando} type="submit" value="Ligar" className="btn btn-warning"/>
                                        )
                                        }
                                        {
                                        this.state.hiddenButton && (
                                        <img className="mb-2" src="/imagens/estamos-ligando.gif" loading="lazy"/>
                                        )
                                        }
                                        <button ref={button => this.inputElement = button} type="button" className="btn btn-secondary ml-2" data-dismiss="modal">Fechar</button>
                                    </div>
                                    <div className="col-lg-7 text-right "> <img ref={img => this.videoMomento = img} src="" loading="lazy"/>
                                    </div>
                                    <div hidden> <img ref={img => this.videoMomentoHidden = img} src="" loading="lazy"/> 
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
    </>

        );
            }   
}

export default TeLigamos;
