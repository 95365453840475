import React, { Component } from 'react';


class CriteoCarrinho extends Component {
    constructor(props){
        super(props)
        this.state = {
        }
    }
    componentDidMount = () => {
        var ss=localStorage.getItem("ss");ss=JSON.parse(ss);if(ss==null){var cemail="";}else{var cemail=ss['email'];};

        let tbProdutos = localStorage.getItem("tbProdutos");
        tbProdutos = JSON.parse(tbProdutos);
        if(tbProdutos == null){
            tbProdutos = []
        }

        if(tbProdutos.length > 0){
            let cprodutos = tbProdutos.map(item => {
                return {
                    id:item.sku,
                    price:item.preco,
                    quantity:item.qty
                }
            });
            const script = document.createElement("script");
            script.src = "//static.criteo.net/js/ld/ld.js";
            script.async = true;
            document.body.appendChild(script);

            let tela = "d";
            if(window.innerWidth > 575 && window.innerWidth < 1024){
                tela="t"
            }
            if(window.innerWidth < 575){
                tela="m"
            }
            window.criteo_q = window.criteo_q || [];
            window.criteo_q.push(
                { event: "setAccount", account: 32004 },
                { event: "setEmail", email: `${cemail}` },
                { event: "setSiteType", type: `"${tela}"`},
                { event: "viewBasket", item:cprodutos}
            );
        }
    }
    render(){
        return (
            <></>
        )
    }
}

export default CriteoCarrinho;
