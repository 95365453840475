import React, {Component} from 'react';
import { animateScroll as scroll } from 'react-scroll';


class ButtonTop extends Component {
    constructor(props){
        super(props)
        this.state = {  display: "none"  }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(null, this))
    }
    handleScroll(self, e) {
        if(window.pageYOffset > 500){
            self.setState({display: ""})
        }else{
            self.setState({display: "none"})
        }
    }
    ScrollTo() {
        scroll.scrollToTop();
    }
    render() {
        return (
            <>
                <button 
                    style={{ display : this.state.display }} 
                    onClick={this.ScrollTo} 
                    className="btn btn-danger rounded-circle move-top">
                    <i className="fas fa-chevron-up"></i>
                </button>
                <style jsx="true">{`
                    .move-top{ position: fixed; bottom: 50px;right: 20px; padding:10px 15px; }
                    `}</style>
            </>
        );
    }   
}

export default ButtonTop;
