import React, { Component } from 'react';
import Footer from '../comuns/Footer';
import MinutoLojamundi from '../comuns/MinutoLojamundi';
import SeloSeguranca from '../comuns/SeloSeguranca'
import ContatoInternas from '../comuns/ContatoInternas';
import NewsLetter from '../comuns/NewsLetter';
import BannerFooter from '../comuns/BannerFooter';



class FooterGroup extends Component {
    constructor(props){
        super(props)
        this.state = {
        }
    }
    render(){
        return (
            <>
            <SeloSeguranca/>
            <ContatoInternas/>
            {/*<MinutoLojamundi/>*/}
            <BannerFooter/>
            <NewsLetter/>
            <Footer/>

    </>
        )
    }
}
export default FooterGroup;
