import React, { Component } from "react";

class Lgpd extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.lgpd = React.createRef();
  }

  componentDidMount = async () => {
    if (!JSON.parse(localStorage.getItem("avisoLGPD"))) {
      setTimeout(() => {
        this.lgpd.current.removeAttribute("hidden");
      }, 3000);
    }
  };
  saveLGPD = () => {
    localStorage.setItem("avisoLGPD", JSON.stringify(true));
    this.lgpd.current.setAttribute("hidden", true);
  };
  render() {
    return (
      <div
        ref={this.lgpd}
        style={{
          position: "fixed",
          bottom: "0px",
          left: "0px",
          width: "100%",
        }}
        hidden>
        <div className="container mb-5">
          <div
            style={{ "boxShadow": "3px 3px 10px rgba(0,0,0,0.3)" }}
            className="card alert alert-warning  rounded col-sm-10 p-0 m-auto">
            <div className="card-body ">
              <div className="row justify-content-center">
                <div className="col-sm-auto text-center">
                  <img src="/imagens/icone-lgpd.png" />
                </div>
                <div
                  style={{ "fontSize": "13px" }}
                  className="col-sm-auto py-2 text-dark">
                  Ao navegar neste site, você aceita os cookies que usamos para
                  melhorar sua experiência.{" "}
                  <a href="/politica-de-privacidade">
                    <span className="text-danger">Política de Privacidade</span>
                  </a>
                  .
                </div>
                <div className="col-sm-auto  text-center">
                  <button
                    onClick={this.saveLGPD}
                    type="button"
                    className="btn btn-danger btn-sm">
                    Entendi
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Lgpd;
