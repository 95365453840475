import React, { Component } from 'react';
import {sortByAlphabetical} from '../../funcoes'

class BarraLateral extends Component {
   constructor(props){
      super(props)
      this.state = {
      }
   }
   render(){
      return (
         <aside className="d-none d-lg-block">
            <div className="card mt-5 mb-5">
               <div className="card-header bg-danger text-white">
                  Categorias
               </div>
               <div className="card-body" style={{fontSize: "12px"}}>
                  {sortByAlphabetical(this.props.filtroCategoria, 'name').map((item, key) => 
                  <a className="linkMenu" key={key} href={`/${item.url_categoria}`}>
                     <div className="card-text">
                        <i className="fas fa-angle-right"></i> {item.name} <span className="badge badge-light">{item.total}</span>
                     </div>
                  </a>
                  )}
               </div>
            </div>
         </aside>
      )
   }
}

export default BarraLateral;
