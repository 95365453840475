import React, { Component } from 'react';

class NewsLetter extends Component {
    constructor(props){
        super(props)
        this.state = {
            email:'',
            name:'',
            warning:false
        }
    }
    sendMailchimp = async (e) => { 
        e.preventDefault()
        if(this.state.email && this.state.name){
            //let url = `https://lojamundi.us9.list-manage.com/subscribe/post?u=1c4771a610266b8e172b589d2&amp;id=db8f696e52`;
           let url = `/api2/newsLetter`;
            //let formData = new FormData();
            //formData.append('EMAIL',this.state.email)
            //formData.append('b_1c4771a610266b8e172b589d2_db8f696e52','')
            //formData.append('subscribe','OK')
            //formData.append('Email',this.state.email)
            //formData.append('Name',this.state.name)
            //formData.append('MachineCode',652922)
            //formData.append('EmailSequenceCode',1494228)
            //formData.append('SequenceLevelCode',1)
            await fetch(url,
                {
                    method:'POST',
                    body:JSON.stringify(this.state),
                    headers: { "Content-Type": "application/json" },
                    //mode: 'no-cors',
                }
            )
           this.setState({email:'', name:'', warning:true})
        }
    }

    render(){
        return (
            <>
            <div style={{"padding":"3em 0","background":"#fa1818","backgroundImage":"url(/imagens/telefone-ip-destaque-lojamundi.jpg)","backgroundRepeat":"no-repeat"}} >
                <div className="text-center text-white pt-5 pb-4"><h3><strong>Assine nossa Newsletter! É gratuito!</strong></h3></div>
            <div className="text-center text-white pb-3">Cadastre seu nome e email para receber novidades e materiais gratuitos da Lojamundi.</div>
                <div className="container pb-5">
                    {
                    this.state.warning && (
                    <div className="row justify-content-center pb-2">
                        <div className="alert alert-success" role="alert">
                            Obrigado por se inscrever!! 
                        </div>
                    </div>
                    )
                    }
                    {
                    this.state.warning || (
                    <div className="row justify-content-center pb-2">
                        <form className="col-sm-7" onSubmit={(e) => this.sendMailchimp(e)}>
                            <div className="form-row align-items-center">
                                <div className="col-sm-4 pb-2">
                                   <input style={{"outline": "0","padding": "10px","background": "0","border": "1px solid #e6e6e6","fontSize": "14px","color": "#fff","width":"100%"}} onChange={e => this.setState({name:e.target.value})} value={this.state.name} type="name" name="NAME" className="" placeholder="Nome" required="required"/>
                                </div>
                                <div className="col-sm-8 pb-2">
                                    <input style={{"outline": "0","padding": "10px","background": "0","border": "1px solid #e6e6e6","fontSize": "14px","color": "#fff","width":"100%"}} onChange={e => this.setState({email:e.target.value})} value={this.state.email} type="email" name="EMAIL" className="" placeholder="Email" required="required"/>
                                </div>
                                <div className="col-auto m-auto">
                                    <input type="submit" value="Cadastre-se" name="subscribe" className="btn btn-light btn-sm"/>
                                </div>
                            </div>
                        </form>
                    </div>
                    )
                    }
                    
                </div>
            </div>





    </>
        )
    }
}

export default NewsLetter;
