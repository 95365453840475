import React, { Component } from 'react';
import TeLigamos from './TeLigamos.js'
//import DirectCall from './DirectCall.js'
import Carrinho from './Carrinho'
import Logado from './Logado'
import {ContCarrinho} from '../ContextLojamundi'

class TopBar extends Component {
    constructor(props){
        super(props)
        this.state = {
            
        }
    }
    static contextType = ContCarrinho
    render(){
        return (
            <>
            <nav style={{"backgroundImage":"url(/imagens/telefone-ip-topbar.png)","backgroundRepeat":"repeat-x","height":"47px", "backgroundPosition":"top", "backgroundPosition":"left"}} className="navbar navbar-expand-sm navbar-dark mt-lg-0">
                <div className="container">
            {
               !this.context.outService && this.context.endPoint  === 'desktop' && (
                   <div className="text-left">
                    <a href="/sala-comercial" target="_blank"><img src="/imagens/fale-ao-vivo-header.png" /></a>
                   </div>)
                   
                   }
                    <ul className="navbar-nav flexDirectionHeader ml-auto">
                        <li className="nav-item ">
                        <div className="row">
            {
               !this.context.outService && this.context.endPoint  === 'desktop' && (
                            <TeLigamos/>
               )
               }
            {/*
               !this.context.outService && this.context.endPoint === 'mobile' && (
                            <DirectCall/>
               )
                  */}
                            <Carrinho/>
                        </div>
                        </li>
                    </ul>
                </div>
            </nav>
             {
                  this.context.ss.token && <Logado/>
             }  
            </>
            )
    }
}
export default TopBar;
