import Head from 'next/head'

export default (props) => {
    let {title, description, canonico} = props;
    return (
        <Head>
            <link rel="manifest" href="/manifest.json"/>
            <meta name="viewport" content="width=device-width, initial-scale=1,shrink-to-fit=no" />
            <meta charSet="utf-8" />
            <meta name="robots" content="index, follow"></meta>
            <meta name="theme-color" content="#0a0a0f"/>
            <meta name="msapplication-navbutton-color" content="#0a0a0f"/>
            <meta name="apple-mobile-web-app-status-bar-style" content="#0a0a0f"/>
            
            <link rel="icon" type="image/png" href="/imagens/favicon-lojamundi.ico" />
            <meta name="apple-mobile-web-app-capable" content="yes"/>
            <meta name="apple-mobile-web-app-title" content="LojaMundi"/>
            <link rel="apple-touch-icon" href="/static/manifest/logoLojamundi180x180.png"/>
            {
            canonico && (<link rel='canonical' href={`https://www.lojamundi.com.br/${canonico}`}/>)
            }
            {
            title && (<title>{title}</title>)
            }
            {
            description && (<meta name="description" content={description}/>)
            }
            
        </Head>
    )
}
