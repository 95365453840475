import React, { Component } from 'react';
import {
    removeMaskNumber,
    addMaskCel,

} from '../../funcoes'
import {HOST} from '../../host.js'

class ContatoInternas extends Component {
    constructor(props){
        super(props)
        this.state = {
            nome:'',
            cel:'',
            email:'',
            msg:'',
            endURL:'',
            enviada:false
        }
    }
    limpaForm = () => {
        this.setState({
            email:'',
            nome:'',
            cel:'',
            msg:'',
        })
    }

    limpaCampo = (e, tam) => {
        if(e.target.value.length < tam){
            this.setState({[e.target.name]:''})
        }
    }

    getDataInput = (e) => {
        this.setState({[e.target.name]:e.target.value})
    }
    sendData = async (e) => {
        e.preventDefault()
        await this.setState({endURL:window.location.href})
        if(this.state.emailErro === true) return
        let url = `${HOST}/api2/contato`;
        let rt = await fetch(url,
            {
                method:'POST',
                body:JSON.stringify(this.state),
                headers:{'Content-Type': 'application/json'}
            }
        )
        rt = await rt.json()
        if(rt){
            this.limpaForm()
            this.setState({enviada:true})
        }
    }

    render(){
        return (
            <div style={{"backgroundImage":"url(/imagens/background-contato3.png)","backgroundPosition":"top","backgroundRepeat":"no-repeat","backgroundColor":"#d3dade"}}>
                <div id="formContact" className="container">
                    <div className="text-center pt-4 mb-2 titulo"><img src="/imagens/imagemTitulo.png" loading="lazy"/><br/>ENTRE EM CONTATO COM A LOJAMUNDI.</div>



                    <div className="row">
                        <div className="col-sm-12 ">

                            <div className="m-auto " style={{"height":"5px;","width":"120px;","backgroundColor":"#ff1110;"}}></div>

                            <div className="pt-4">
                                {
                                !this.state.enviada ? (
                                <form onSubmit={e => this.sendData(e)}>
                                    <div className="row pr-5">
                                        <div className="col-sm pb-2">
                                            <input type="text" onChange={e => this.getDataInput(e)} className="form-control form-control-sm" placeholder="Nome" name="nome" value={this.state.nome} maxLength="100" required="required"/>
                                        </div>
                                        <div className="col-sm pb-2">
                                            <input type="text" name="cel" onBlur={e => this.limpaCampo(e, 11)} onChange={e => this.getDataInput(removeMaskNumber(e))} value={addMaskCel(this.state.cel)} className="form-control form-control-sm" placeholder="Celular" maxLength="11" required="required"/>
                                        </div>
                                        <div className="col-sm">
                                            <input type="email" name="email" onChange={e => this.getDataInput(e)} value={this.state.email} className="form-control form-control-sm" placeholder="Digite seu Email" maxLength="100" required="required"/>
                                        </div>
                                    </div>
                                    <div className="form-group pt-2 pr-5">
                                        <textarea name="msg" onChange={e => this.getDataInput(e)} value={this.state.msg} maxLength="500" className="form-control form-control-sm" rows="3" placeholder="Deixe sua Mensagem" required="required"></textarea> 
                                    </div>
                                    <div className="pt-2 pb-4 text-center pr-5"><button type="submit" className="btn btn-outline-danger btn-sm">Enviar</button></div>
                                </form>
                                ):
                                (
                                <div className="alert alert-success" role="alert">
                                    Obrigado por enviar sua mensagem! Ela será respondida o mais breve possível.
                                </div>
                                )
                                }

                            </div>
                        </div>

                    </div>








                </div>
            </div>
            )
            }
            }

            export default ContatoInternas;
