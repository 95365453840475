import React, { Component } from 'react';

class Breadcrumbs extends Component {
    constructor(props){
        super(props)
        this.state = {
        }
    }
    render(){

        return (
            <div style={{"backgroundColor":"#474646","height":"30px"}} className="d-none d-sm-block">
                <div className="container">
                    <nav aria-label="breadcrumb" role="navigation">
                        <ol style={{"backgroundColor":"#474646"}} className="breadcrumb bg-dark">
                            <li style={{"marginTop":"-5px","fontSize":"12px"}} className="breadcrumb-item">
                                <a className="linkBreadcrumb" href="/">
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> Home
                                </a>
                            </li>
                            {
                            this.props.breadData && this.props.breadData.length > 0 && (
                            <>
                            {
                            this.props.breadData.map((item, index) => 
                            <li style={{"marginTop":"-5px","fontSize":"12px"}} className="breadcrumb-item" key={index}>
                                <a className="linkBreadcrumb" href={`/${item.link}`}>
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> {item.name}
                                </a>
                            </li>
                            )
                            }
                    </>
                            )
                            }
                        </ol>
                    </nav>
                </div>
                <div style={{"backgroundImage":"url(/imagens/sombra-breadcrumb.png)","backgroundRepeat":"repeat-x","height":"31px","marginTop":"-22px"}}></div>
            </div>


            )
    }
}

export default Breadcrumbs;
