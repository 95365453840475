import React, {Component} from 'react';
import fetch from 'isomorphic-fetch';
const {HOST} = require('../../host.js')

const GridPC = ({lista}) => {
   return(
      <div className="row w-100 bg-light ml-0 rounded-left mt-1">
         <table className="table table-hover" id="table">
            <tbody>
               {lista.map((item, index) => {
                  if(item.imageName){
                     return(
                        <React.Fragment key={index}>
                           <a href={`/${item.url_publica}`}>
                              <tr style={{'cursor':'pointer'}}>
                                 <td className="p-0" key={index}> 
                                    <picture>
                                       <source type="image/webp" srcSet={`/imagens/produtos/mini/${item.imageName.replace(/\.[a-z]{3}$/,'')+'.webp'}`}/>
                                       <img width="40" height="40" src={`/imagens/produtos/mini/${item.imageName.endsWith('jpg') ? item.imageName : item.imageName+'.jpg'}`} alt={item.name} loading="lazy"/>
                                    </picture>
                                 </td>
                                 &nbsp;&nbsp;&nbsp;<td className="p-0 mr-5"> {item.name}</td><br/>
                                 &nbsp;&nbsp;&nbsp;<td className="p-0"></td>
                              </tr>
                           </a>
                           <br/>
                        </React.Fragment>
                     )
                  }else{
                     return <></>
                  }
               })}
               <style jsx>{`
                a:link {
                color: gray;
                text-decoration:none;
                font-family:'latoregular';
                }

                a:visited {
                color: gray;
                }

                a:hover {
                color: red;
                fontSize:18px;
                text:bold;
                }

                a:active {
                color: red;
                }
                `}</style>
            </tbody>
         </table>
         <hr/>
      </div>
   );
}

class Busca extends Component {
   constructor(props){
      super(props)
      this.state = {
         termo:'',
         lista:[],
         speech:false,
         speechApi:{},
         speechIconPulse:false
      }
   }
   componentDidMount = () => {
      if(window.SpeechRecognition || window.webkitSpeechRecognition){
         let speechApi = window.SpeechRecognition || window.webkitSpeechRecognition
         this.setState({
            speech:true,
            speechApi
         })
      }
   }
   startSpeech = async() => {
      this.setState({speechIconPulse:true})
      let gravarAudio = new this.state.speechApi();
      gravarAudio.continuous = false;
      gravarAudio.interimResults = false;
      gravarAudio.lang = "pt-BR";
      gravarAudio.start();
      gravarAudio.onresult = async(event) => {
         let audio = event.results[0][0].transcript;
         await this.setState({termo:audio})
         let url = `${HOST}/api2/busca?termo=${this.state.termo}`
         try{
            let rt = await fetch(url)
            rt = await rt.json()
            this.setState({lista:rt})
         }catch(e){}
      }
      gravarAudio.onend = (() => this.setState({speechIconPulse:false}))
   }

   getTermo = async (e) => {
      let termo = e.target.value;
      await this.setState({termo})
      //if(termo.length % 2 == 0){
      let url = `${HOST}/api2/busca?termo=${termo}`
      try{
         let rt = await fetch(url)
         rt = await rt.json()
         this.setState({lista:rt})
      }catch(e){}

      //}
   }

   getList = async () => {
      let termo = this.state.termo
      let url = `${HOST}/comprar/${termo}`
      window.location.assign(url)
   };
   render() {
      return (
         <div className="col-sm-12 col-md-12" style={{'zIndex':'100'}}  onMouseLeave={e => this.setState({lista:[]})}>
            <div className="input-group">
               <input onChange={this.getTermo} onKeyDown={(e) => e.key === 'Enter' ? this.getList() : {}} value={this.state.termo} className="form-control" type="search" placeholder="Buscar por Produto" aria-label="Search"/>
               {this.state.speech && 
               <button className="rounded bg-light" onClick={this.startSpeech}>
                  <i className={this.state.speechIconPulse ? "fa fa-microphone fa-pulse" : "fa fa-microphone"} aria-hidden="true"></i>
               </button>
               }   
               <button onClick={this.getList} className="btn btn-secondary">
                  <i className="fa fa-search" aria-hidden="true"></i>
               </button>
            </div>
            {this.state.lista.length >= 1 && this.state.termo.length >= 2 && <GridPC lista={this.state.lista}/> }
         </div>

      );
   }
}

export default Busca;
