import React, { Component } from 'react';
import {ContCarrinho} from '../ContextLojamundi';

class MenuSuperiorCel extends Component {
    constructor(props){
        super(props)
        this.state = {
        }
    }
    static contextType = ContCarrinho;
    render(){
        return (
            <div className="d-block d-lg-none">
                <div className="row backgroundTelefoneCelular">
                    <div className="container text-center mt-3">
                        <div className="justify-content-center"><a href="/">
                                <picture>
                                    <source type="image/webp" srcSet="/imagens/logomarca.webp"/>
                                    <img src="/imagens/logomarca.png" loading="lazy"/>
                                </picture>
                        </a></div>
                        <div className="row mt-3 justify-content-center">
                            {
                            this.context.ss.token ? (
                            <>
                            <a href="/painel"><img className="mr-2" src="/imagens/btMinhaConta.png"/></a>
                            <a href="/"><img onClick={e => this.context.removeSession()} className="mr-2" src="/imagens/bt-sair.png" loading="lazy"/></a>
                    </>
                    )
                    :
                    (
                    <>
                    <a href="/login"><img className="mr-2" src="/imagens/btMinhaConta.png" loading="lazy"/></a>
                    <a href="/login"><img className="mr-2" src="/imagens/bt-entrar.png" loading="lazy"/></a>
            </>
            )
            }
            <a href="/cadastro"><img className="mr-2" src="/imagens/btCadastro.png" loading="lazy"/></a>
        </div>
        <div className="row mt-3 justify-content-center">
            <a href="/servicos"><img className="mr-2" src="/imagens/btServicos.png" loading="lazy"/></a>
            <a href="/blog"><img className="mr-2" src="/imagens/btBlog.png" loading="lazy"/></a>
            <a href="/contato"><img className="mr-2" src="/imagens/btContato.png" loading="lazy"/></a>
        </div>
        
                
        
             
             
                       {/* <a href='https://api.whatsapp.com/send?phone=5561991375620&amp;text=Ol%c3%a1%2c%20gostaria%20de%20saber%20mais%20informa%c3%a7%c3%b5es%20sobre%20seus%20produtos.'  target='_blank'  className="btn btn-sm mb-3 text-white">
               <span style={{"fontSize":"16px"}}><i className="fab fa-whatsapp" aria-hidden="true"></i> 61 9 9137 5620
               </span>
               <style jsx>{`
                    backgroundColor:#464444
                    }
                    `}</style>
               </a>*/}
             
       
        
        
        
        
    </div>
</div>
    </div>

            )
}
}

export default MenuSuperiorCel;
