import React, { Component } from 'react';
import {ContCarrinho} from '../ContextLojamundi';

class Logado extends Component {
    constructor(props){
        super(props)
        this.state = {
            valor:''
        }
    }
    static contextType = ContCarrinho
    logout = () => {
        this.context.removeSession()
        window.location.assign('/')
    }
    render(){
        return (
            <div className="py-1" style={{cursor:'pointer',"fontSize":"16px","backgroundImage":"url(imagens/background-logado.png)","backgroundRepeat":"repeat-x","backgroundColor":"595959;"}} >
                <div style={{cursor:'pointer',"fontSize":"16px"}} className="container text-right"><a href='/painel' style={{"color":"#da3747"}} ><i className="fas fa-user"></i> Olá, {this.context.ss.name}. | <i className="fas fa-sign-out-alt" onClick={this.logout}><span style={{"fontFamily":"latoregular" }}> Sair</span></i></a>
                </div>
            </div>

            )
    }
}

export default Logado;

