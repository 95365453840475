import React, { Component } from 'react';
import {ContCarrinho} from '../ContextLojamundi'
import Logado from './Logado'
import {sortByAlphabetical} from '../../funcoes'


const chunk = (array, size) => {
    const chunked_arr = [];
    let index = 0; 
    while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
    }
    return chunked_arr;
}


class NavBarSuperior extends Component {
    constructor(props){
        super(props)
        this.bgColor = React.createRef();
        this.state = {
            categorias:[],
        }
    }
    componentDidMount = () => {
        let isMobile = window.innerWidth
        if(isMobile <= 768){
            this.bgColor.current.style.backgroundColor = "rgb(81,81,81)"
        }
    }
    static contextType = ContCarrinho
    componentWillMount = () => {
        let cat = sortByAlphabetical(this.props.filtroCategoria, 'name')
        let catNova = chunk(cat, 10)
        this.setState({categorias:catNova})
    }
    render(){
        //console.log(this.state.categorias)
        return (
            <nav style={{"backgroundColor":"#464444","backgroundImage":"url(/imagens/telefone-ip.png)","height":"62px"}} className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div ref={this.bgColor} style={{"borderRadius":"10px","padding":"10px"}} className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <a className="nav-link text-white" href="/"><i className="fas fa-home"></i> HOME <span className="sr-only">(current)</span></a>
                            </li>


                            <li className="nav-item dropdown mega-dropdown" style={{"position":"static !important"}}>
                                <a className="nav-link dropdown-toggle text-white" id="navbarDropdownMenuLink2" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false"><i className="fas fa-laptop"></i> PRODUTOS
                                    <span className="sr-only">(current)</span>
                                </a>
                                <div className="row justify-content-center">
                                    <div style={{"backgroundImage":"url(/imagens/bg-submenu2.jpg)","backgroundRepeat":"repeat-x","backgroundPosition":"top","backgroundColor":"#e8e7e7"}} className="dropdown-menu mega-menu v-2 z-depth-1 special-color py-3 px-3 w-100"
                                        aria-labelledby="navbarDropdownMenuLink2"><div className="mb-1" style={{"backgroundColor":"#d0cece","width":"100%","height":"5px"}}></div>
                                        <div style={{"backgroundColor":"#d0cece","width":"100%","height":"1px"}}></div>
                                        <div className="row">
                                            {
                                            this.state.categorias.map((item, key) => {
                                            return (
                                            <div key={key} className="col-md-6 col-xl-3 sub-menu mb-xl-0 mb-4">
                                                <h6 className="sub-title text-uppercase fontWeight-bold white-text"></h6>
                                                <ul  className="list-unstyled">
                                                    {
                                                    item.map((item2, key2) =>{
                                                    return(
                                                    <li key={key2}>
                                                        <a className="menu-item pl-0 linkMenu" href={`/${item2.url_categoria}`}>
                                                            <i className="fas fa-caret-right pl-1 pr-3"></i> {item2.name}
                                                        </a>
                                                    </li>
                                                    )
                                                    }
                                                    )
                                                    }
                                                </ul>

                                            </div>
                                            )
                                            })
                                            }
                                        </div>
                                        <div className="mt-3" style={{"backgroundColor":"#d0cece","width":"100%","height":"1px"}}></div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="/promocao"><i className="fas fa-tags"></i> PROMOÇÕES</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="/servico-lojamundi"><i className="fas fa-user"></i> SERVIÇOS</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="/blog"><i className="fas fa-comments"></i> BLOG</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="/cadastro"><i className="fas fa-edit"></i> CADASTRE-SE</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href={this.context.ss.token ? "/painel" : "/login"}><i className="fas fa-sign-in-alt"></i> MINHA CONTA</a>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
            )
            }
            }

export default NavBarSuperior;
