import React, { Component } from 'react';
import { ContCarrinho } from '../ContextLojamundi'
import ButtonTop from './ButtonTop';
import Lgpd from './lgpd';

class Footer extends Component {
   constructor(props) {
      super(props)
      this.state = {
      }
   }
   static contextType = ContCarrinho
   render() {
      return (

         <footer>

            <div className="footerLojamundi">
               <div className="container"><Lgpd /></div>
               <div className="container text-center">

                  <div className="row pt-5">
                     <div className="col-md-3">
                        <h3 className="tituloFooter">Para você, nosso cliente</h3>
                        <nav className="navbar navbar-dark">
                           <ul className="navbar-nav  flex-column text-left" role="tablist" aria-orientation="vertical">
                              <li className="nav-item">
                                 <a className="nav-link" href={this.context.ss.token ? "/painel" : "/login"}><i className="fa fa-chevron-right" aria-hidden="true"></i> Minha Conta </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href={this.context.ss.token ? "/painel" : "/login"}><i className="fa fa-chevron-right" aria-hidden="true"></i> Entrar no Painel</a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/cadastro"><i className="fa fa-chevron-right" aria-hidden="true"></i> Cadastre-se </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/politica-de-troca"><i className="fa fa-chevron-right" aria-hidden="true"></i> Política de Troca </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/politica-de-privacidade"><i className="fa fa-chevron-right" aria-hidden="true"></i> Política de Privacidade </a>
                              </li>


                              <li className="nav-item">
                                 <a className="nav-link" href="/termos-condicoes"><i className="fa fa-chevron-right" aria-hidden="true"></i> Termos e Condições </a>
                              </li>
                           </ul>
                        </nav>
                     </div>
                     <div className="col-md-3">
                        <h3 className="tituloFooter">Marcas que Representamos</h3>
                        <nav className="navbar navbar-dark">
                           <ul className="navbar-nav  flex-column text-left" role="tablist" aria-orientation="vertical">
                              
                              <li className="nav-item">
                                 <a className="nav-link" href="/directcall"><i className="fa fa-chevron-right" aria-hidden="true"></i> DirectCall </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/grandstream"><i className="fa fa-chevron-right" aria-hidden="true"></i> Grandstream </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/flyingvoice"><i className="fa fa-chevron-right" aria-hidden="true"></i> Flyinvoice </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/synway"><i className="fa fa-chevron-right" aria-hidden="true"></i> Synway </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/logitech"><i className="fa fa-chevron-right" aria-hidden="true"></i> Logitech </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/ubiquiti"><i className="fa fa-chevron-right" aria-hidden="true"></i> Ubiquiti </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/mikrotik"><i className="fa fa-chevron-right" aria-hidden="true"></i> Mikrotik </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/khomp"><i className="fa fa-chevron-right" aria-hidden="true"></i> Khomp</a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/voxmundi"><i className="fa fa-chevron-right" aria-hidden="true"></i> Voxmundi </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/openvox"><i className="fa fa-chevron-right" aria-hidden="true"></i> OpenVox </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/audiocodes"><i className="fa fa-chevron-right" aria-hidden="true"></i> AudioCodes </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/yealink"><i className="fa fa-chevron-right" aria-hidden="true"></i> Yealink </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/hikvision"><i className="fa fa-chevron-right" aria-hidden="true"></i> Hikvision </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/plantronics"><i className="fa fa-chevron-right" aria-hidden="true"></i> Plantronics </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/felitron"><i className="fa fa-chevron-right" aria-hidden="true"></i> Felitron </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/intelbras"><i className="fa fa-chevron-right" aria-hidden="true"></i> Intelbras </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/fanvil"><i className="fa fa-chevron-right" aria-hidden="true"></i> Fanvil </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/panasonic"><i className="fa fa-chevron-right" aria-hidden="true"></i> Panasonic </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/zox"><i className="fa fa-chevron-right" aria-hidden="true"></i> Zox Telecom </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/aligera"><i className="fa fa-chevron-right" aria-hidden="true"></i> Aligera </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/zyxel"><i className="fa fa-chevron-right" aria-hidden="true"></i> Zyxel </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/avaya"><i className="fa fa-chevron-right" aria-hidden="true"></i> Avaya </a>
                              </li>
                           </ul>
                        </nav>
                     </div>
                     <div className="col-md-3">
                        <h3 className="tituloFooter">Institucional Lojamundi</h3>
                        <nav className="navbar navbar-dark">
                           <ul className="navbar-nav  flex-column text-left" role="tablist" aria-orientation="vertical">
                              <li className="nav-item">
                                 <a className="nav-link" href="/quem-somos"><i className="fa fa-chevron-right" aria-hidden="true"></i> Quem Somos </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/servicos"><i className="fa fa-chevron-right" aria-hidden="true"></i> Serviços </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/aluguel-de-tecnologia"><i className="fa fa-chevron-right" aria-hidden="true"></i> Sua empresa também pode alugar nossos produtos </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/duvidas-aluguel-de-tecnologia"><i className="fa fa-chevron-right" aria-hidden="true"></i> Dúvidas sobre aluguel de produtos? </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/lojamundi-e-confiavel"><i className="fa fa-chevron-right" aria-hidden="true"></i> Lojamundi é confiável? </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/oxcom-pabxvirtual"><i className="fa fa-chevron-right" aria-hidden="true"></i> OxCom - PABX Virtual </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/pabx-ip"><i className="fa fa-chevron-right" aria-hidden="true"></i> PABX IP </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/callcenter"><i className="fa fa-chevron-right" aria-hidden="true"></i> CallCenter </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/oxcom-gravador"><i className="fa fa-chevron-right" aria-hidden="true"></i> OxCom Gravador </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/simvox"><i className="fa fa-chevron-right" aria-hidden="true"></i> SimVox </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/parceiros"><i className="fa fa-chevron-right" aria-hidden="true"></i> Parceiros e Clientes </a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/perguntasFrequentes"><i className="fa fa-chevron-right" aria-hidden="true"></i> Perguntas Frequentes</a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/revenda"><i className="fa fa-chevron-right" aria-hidden="true"></i> Seja um Revendedor Lojamundi</a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" href="/"><i className="fa fa-chevron-right" aria-hidden="true"></i> MarketPlace Lojamundi</a>
                              </li>
                           </ul>
                        </nav>

                     </div>
                     <div className="col-md-3">
                        <h3 className="tituloFooter">Fale com a Lojamundi</h3>
                        <div>

                           <a className="linkRodapeLojamundi" href="https://web.whatsapp.com/send?phone=5561991375620&amp;text=Ol%c3%a1%2c%20gostaria%20de%20saber%20mais%20informa%c3%a7%c3%b5es%20sobre%20seus%20produtos." target="_blank"><img src="/imagens/zap.png" loading="lazy" />Comercial: 61 9 9137 5620</a><br /><br />
                           11 2666 4242 | 21 2169 8855 <br />
                           31 4042 1799 | 71 3273 7636 <br />
                           41 3208 4524 | 48 4042 1888 <br />
                           61 3797 8790 | 85 3052 2727 <br />
                           <br />
                           <a className="linkRodapeLojamundi"
                              href="https://web.whatsapp.com/send?phone=556137978786&text=Ol%c3%a1%2c%20gostaria%20de%20agendar%20um%20suporte"
                              target="_blank">

                              <img src="/imagens/zap.png" loading="lazy" />Suporte: 61 3797 8786

                           </a> <br /><br />
                           <div className="text-left pl-4">
                              <i className="fab fa-skype"></i> consultoria.lojamundi <br />
                              <i className="fab fa-skype"></i> jakeline_mj <br />
                              <i className="fab fa-skype"></i> moises.osmundo <br />
                              <i className="fab fa-skype"></i> vendedor_lojamundi <br />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="container">
                  <hr style={{ "backgroundColor": "#767575" }} />
                  <div className="row">
                     <div className="text-center col-6 mb-5">
                        <h3 className="tituloFooter"> <p>Formas de Pagamento</p></h3>
                        <img src="https://www.lojamundi.com.br/imagens/cartaoVisaNegativo.png" alt="Forma de Pagamento via cartao VISA" loading="lazy" />
                        <img src="https://www.lojamundi.com.br/imagens/cartaoMastercardNegativo.png" alt="Forma de Pagamento via cartao MASTERCARD" loading="lazy" />
                        <img src="https://www.lojamundi.com.br/imagens/cartaoEloNegativo.png" alt="Forma de Pagamento via cartao ELO" loading="lazy" />
                        <img src="https://www.lojamundi.com.br/imagens/cartaoHipercardNegativo.png" alt="Forma de Pagamento via cartao HIPERCARD" loading="lazy" />
                        <img src="https://www.lojamundi.com.br/imagens/cartaoAmexNegativo.png" alt="Forma de Pagamento via cartao AMERICAN EXPRESS" loading="lazy" />
                        <img src="https://www.lojamundi.com.br/imagens/cartaoDinersNegativo.png" alt="Forma de Pagamento via cartao DINERS" loading="lazy" />
                        <img src="https://www.lojamundi.com.br/imagens/cartaoMercadoLivreNegativo.png" alt="Forma de Pagamento via cartao MERCADO PAGO" loading="lazy" />
                        <img src="https://www.lojamundi.com.br/imagens/cartaoPaypalNegativo.png" alt="Forma de Pagamento via PAYPAL" loading="lazy" />
                        <img src="https://www.lojamundi.com.br/imagens/cartoesTransferenciaBancariaNegativo.png" alt="Forma de Pagamento via TRANSFERENCIA BANCARIA" loading="lazy" />
                        <img src="https://www.lojamundi.com.br/imagens/cartoesCreditoBoletoBancario.png" alt="Forma de Pagamento via BOLETO BANCARIO" loading="lazy" />
                     </div>
                     <div className="text-center col-6 mb-5">
                        <h3 className="tituloFooter"> <p>Lojamundi nas Redes Sociais</p></h3>
                        <a className="facebook mr-2" href="https://www.facebook.com/LojamundiTecnologia/" target="_blank">
                           <i className="fab fa-facebook-square" aria-hidden="true" style={{ "fontSize": "36px" }}></i>
                        </a>
                        <a className="twitter mr-2" href="https://twitter.com/lojamundi" target="_blank">
                           <i className="fab fa-twitter-square" aria-hidden="true" style={{ "fontSize": "36px" }}></i>
                        </a>
                        <a className="googleplus mr-2" href="https://plus.google.com/+LojamundiBrdf" target="_blank">
                           <i className="fab fa-google-plus-square" aria-hidden="true" style={{ "fontSize": "36px" }}></i>
                        </a>
                        <a className="instagram mr-2" href="https://www.instagram.com/lojamunditecnologia/" target="_blank">
                           <i className="fab fa-instagram" aria-hidden="true" style={{ "fontSize": "36px" }}></i>
                        </a>
                        <a className="instagram mr-2" href="https://www.youtube.com/channel/UCaevj6jWoc9a9CQYK60NV5Q/featured" target="_blank">
                           <i className="fab fa-youtube" aria-hidden="true" style={{ "fontSize": "36px" }}></i>
                        </a>
                        <a className="instagram mr-2" href="https://www.linkedin.com/in/lojamundi-oficial-8b86831ab/" target="_blank">
                           <i className="fab fa-linkedin" aria-hidden="true" style={{ "fontSize": "36px" }}></i>

                        </a>

                     </div>
                  </div>
               </div>
               <div className="container">
                  <hr style={{ "backgroundColor": "#767575" }} />
                  <p className="text-center pb-3 pt-2">Copyright Lojamundi 2019. Todos os direitos reservados | CNPJ: 20.532.709/0001-45 | Design by
                     <a className="linkRodapeLojamundi" href="https://www.webmundi.net.br" target="_blank" name="WebMundi">WebMundi</a>
                  </p>
               </div>
            </div>
            <ButtonTop />
         </footer>)
   }
}

export default Footer;
