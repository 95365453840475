import React, { Component } from 'react';
import Head from '../components/Head';
import JsBody from '../components/JsBody';
import BodyError from '../components/internas/error/BodyError.js';


class Error extends Component {
    constructor(props){
        super(props)
        this.state = {
        }
    }   
    static async getInitialProps({req}){
        return {
            destaquesHome:req.destaquesHome,
            addRecent:req.addRecent,
            filtroCategoria:req.filtroCategoria
        }
    }

    render(){
        return(
            <>
            <Head />
            <BodyError destaquesHome={this.props.destaquesHome} filtroCategoria={this.props.filtroCategoria} addRecent={this.props.addRecent}/>
            <JsBody />
    </>
        )
    }
}
export default Error
