import React, { Component } from 'react'
import FooterGroup from '../../comuns/FooterGroup';
import TopGroup from '../../comuns/TopGroup'
import ProviderCarrinho from '../../ContextLojamundi';
import BarraLateral from '../../comuns/BarraLateral'


class BodyError extends Component {
    constructor(props){
        super(props)
        this.state = {
            quantidade:1
        }
    }
    setQuantidade = async (value) =>{
        await this.setState({quantidade:value})
    }
    componentDidMount = () => {
        setTimeout(() => window.location.assign('/') ,3000)
    }
    render(){
        return (
            <ProviderCarrinho>
                <TopGroup filtroCategoria={this.props.filtroCategoria}/>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-sm-3 d-none d-lg-block">
                            <BarraLateral filtroCategoria={this.props.filtroCategoria}/>
                        </div>
                        <div className="col-sm-9">
                            <div className="text-center mt-5">
                                <img className="img-fluid" src="/imagens/erro404-lojamundi-2.png"/>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterGroup/>
            </ProviderCarrinho>
            )
    }
}

export default BodyError;
