import React, { Component } from 'react';

class SeloSeguranca extends Component {
    constructor(props){
        super(props)
        this.state = {
            valor:''
        }
    }
    render(){
        return (
            <div style={{"backgroundImage":"url(/imagens/tecnologia-sem-limites-background-seguranca.webp)","backgroundRepeat":"no-repeat","backgroundPosition":"top","backgroundSize":"cover" ,"backgroundColor":"#313131","height":"auto"}}>
                <div className="pt-5 text-center container pb-5">
                    <picture>
                        <source type="image/webp" srcSet="/imagens/reclame-Aqui.webp"/>
                        <img className=" img-fluid" src="/imagens/reclame-Aqui.png" loading="lazy"/>
                    </picture>
                    <picture>
                        <source type="image/webp" srcSet="/imagens/google-site-seguro-lojamundi.webp"/>
                        <img className="img-fluid " src="/imagens/google-site-seguro-lojamundi.png" loading="lazy"/> 
                    </picture>
                    <picture>
                        <source type="image/webp" srcSet="/imagens/https-site-seguro-lojamundi.webp"/>
                        <img className="img-fluid " src="/imagens/https-site-seguro-lojamundi.png" loading="lazy"/> 
                    </picture>
                    <picture>
                        <source type="image/webp" srcSet="/imagens/lets-encrypt-lojamundi.webp"/>
                        <img className="img-fluid " src="/imagens/lets-encrypt-lojamundi.png" loading="lazy"/>
                    </picture>
                    </div>
            </div>


            )
    }
}

export default SeloSeguranca;
