import React, { Component } from "react";

const ContCarrinho = React.createContext();

class ProviderCarrinho extends Component {
  constructor(props) {
    super(props);
    (this.state = {
      qty: 0,
      qtyProductPage: 1,
      tbProdutos: [],
      ss: "",
      outService: true,
      endPoint: "",
    }),
      (this.ModalTeLigamos = React.createRef());
    this.ModalCarrinho = React.createRef();
  }
  componentWillMount() {
    let now = new Date();
    let nowUTC = new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes()
      )
    );
    let start = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      8,
      5,
      0
    );
    let finish = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      17,
      59,
      0
    );
    let day = now.getDay();
    //let day = 1
    if (start < now && now < finish && day != 0 && day != 6) {
     this.setState({ outService: false });
     //this.setState({ outService: true });
    }
  }
  componentDidMount() {
    if (JSON.parse(localStorage.getItem("tbProdutos"))) {
      this.insertAll(JSON.parse(localStorage.getItem("tbProdutos")));
    }
    if (JSON.parse(localStorage.getItem("ss"))) {
      if (JSON.parse(localStorage.getItem("ss")).token) {
        this.createSession(JSON.parse(localStorage.getItem("ss")));
      }
    }
    //let sizeScreen = window.innerWidth > 768 ? false : true;
    let sizeScreen = window.innerWidth > 768 ? "desktop" : "mobile";
    this.setState({ endPoint: sizeScreen });
  }
  componentDidUpdate() {
    localStorage.setItem("tbProdutos", JSON.stringify(this.state.tbProdutos));
  }
  addItem = (obj) => {
    let tbProdutos = [...this.state.tbProdutos];
    let existente = tbProdutos.filter(
      (item) =>
        item.sku == obj.sku &&
        (item.total += obj.preco * obj.qty) &&
        (item.qty += obj.qty)
    );
    let antigo = tbProdutos.filter((item) => item.sku != obj.sku);
    if (!existente.length) {
      antigo.push({ ...obj });
    }
    let listaAtual = [...existente, ...antigo];
    this.setState({
      tbProdutos: listaAtual,
      qty: listaAtual.reduce((soma, item) => soma + item.qty, 0),
    });
    this.ModalCarrinho.current.click();
  };
  removeItem = (indice) => {
    let tbProdutos = [...this.state.tbProdutos];
    let newList = tbProdutos.filter((item, index) => indice != index);
    this.setState({
      tbProdutos: newList,
      qty: newList.reduce((soma, item) => soma + item.qty, 0),
    });
  };
  updateItem = (index, value) => {
    let tbProdutos = [...this.state.tbProdutos];
    for (let [Index, item] of tbProdutos.entries()) {
      if (!value && index == Index) {
        item.qty = 0;
        item.total = 0;
      }
      if (index == Index && value) {
        item.qty = value;
        item.total = item.preco * item.qty;
      }
    }
    this.setState({
      tbProdutos: tbProdutos,
      qty: tbProdutos.reduce((soma, item) => soma + item.qty, 0),
    });
  };
  removeAll = () => {
    this.setState({
      tbProdutos: [],
      qty: 0,
    });
  };
  insertAll = (array) => {
    this.setState({
      tbProdutos: array,
      qty: array.reduce((soma, item) => soma + item.qty, 0),
    });
  };
  createSession = (ss) => {
    localStorage.setItem("ss", JSON.stringify(ss));
    this.setState({ ss });
  };
  removeSession = () => {
    localStorage.removeItem("ss");
    this.setState({ ss: "" });
  };
  setQtyProductPage = (number) => {
    this.setState({ qtyProductPage: number });
  };
  render() {
    return (
      <ContCarrinho.Provider
        value={{
          qty: this.state.qty,
          qtyProductPage: this.state.qtyProductPage,
          setQtyProductPage: this.setQtyProductPage,
          addItem: this.addItem,
          removeItem: this.removeItem,
          removeAll: this.removeAll,
          updateItem: this.updateItem,
          tbProdutos: this.state.tbProdutos,
          createSession: this.createSession,
          removeSession: this.removeSession,
          ss: this.state.ss,
          ModalTeLigamos: this.ModalTeLigamos,
          ModalCarrinho: this.ModalCarrinho,
          outService: this.state.outService,
          endPoint: this.state.endPoint,
        }}>
        {this.props.children}
      </ContCarrinho.Provider>
    );
  }
}

const ConsumerCarrinho = ContCarrinho.Consumer;

export default ProviderCarrinho;
export { ConsumerCarrinho };
export { ContCarrinho };
