import React, { Component } from 'react';
import TopBar from './TopBar'
import NavBarSuperior from './NavBarSuperior'
import Busca from './Busca'
import Box0800PC from './Box0800PC'
import Breadcrumbs from './Breadcrumbs'
import MenuSuperiorCel from './MenuSuperiorCel'
import AoVivoCat from './AoVivoCat'
import {ContCarrinho} from '../ContextLojamundi'


class TopGroup extends Component {
   constructor(props){
      super(props)
      this.state = {
      }
   }

   static contextType = ContCarrinho

   render(){
      return (
         <>
            {
               this.context.endPoint === 'mobile' && (
                  <>
                     <div className="d-block d-lg-none pt-2 pb-2 fixed-top" style={{boxSizing:'border-box',backgroundColor:'#292521'}}>
                        <Busca/>
                     </div>
                     <div className="d-block d-lg-none" style={{marginBottom:"54px"}}/>
                  </>
               )
            }
            <header>
               <TopBar filtroCategoria={this.props.filtroCategoria}/>
               <NavBarSuperior filtroCategoria={this.props.filtroCategoria}/>
            </header>
            {
               this.context.endPoint === 'desktop' && (
                  <div className="d-none d-lg-block" style={{backgroundImage:`url(/imagens/${this.props.bannerCategoria ? this.props.bannerCategoria : 'bgheaderInternas.jpg'})`,backgroundRepeat:'no-repeat',height:'220px',backgroundPosition:'center',backgroundSize:'cover'}}>
                     <div className="container">
                        <div className="row pt-3">
                           <div className="col-lg-2" style={{ pointerEvents: 'none' }}>
                              <a href="/" style={{ pointerEvents: 'auto' }}>
                                 <picture>
                                    <source type="image/webp" srcSet="/imagens/logomarca.webp"/>
                                    <img src="/imagens/logomarca.png"/>         
                                 </picture>
                              </a>
                           </div>
                           <div className="col-lg-7" style={{marginTop:"30px"}}>
                              <Busca/>
                              {
                                 !this.context.outService && (
                                    <AoVivoCat/>
                                 )
                              }
                           </div>
                           <div className="col-lg-3 mt-3">
                              <Box0800PC/>
                           </div>
                        </div>
                     </div>
                  </div>
               )
            }
            {
               this.context.endPoint === 'desktop' && (
                  <Breadcrumbs breadData={this.props.breadData}/>
               )
            }
            {
               this.context.endPoint === 'mobile' && (
                  <MenuSuperiorCel/>
               )
            }
         </>
      )
   }
}
export default TopGroup;
