const removeAcentos = require('remover-acentos')

const receiveURL = (url) => {
   let str = url.split('/')
   let urlEntrante = str
      .filter(item => !/__/.test(item))
      .slice(1)
      .join('/')

   let filters = str
      .filter(item => /__/.test(item))
      .map(item => {
         let f = item.split('__')
         return {
            [decodeURIComponent(f[0])]: decodeURIComponent(f[1])
         }
      })
   return {
      urlEntrante,
      filters
   }
}

const receiveUrlList = (url) => {
   let str = url.split('/')
   let filters = str
      .filter(item => /__/.test(item))
      .map(item => decodeURI(item))
   return {
      filters
   }
}


const changeURL = (text) => {
   let rt = text.split('/').map((item, index) => {
      let name = item.split('-').map(o => o.length > 3 ? o.charAt(0).toUpperCase() + o.substring(1) : o).join(' ')
      let link = text.split('/').slice(0, index + 1).join('/')
      return {
         name,
         link
      }
   })
   return rt
}

const removeMaskNumber = (e) => {
   e.target.value = e.target.value.replace(/[^0-9]/g, '')
   if (!e.target.value) {
      e.target.value = '.'
      return e
   } else {
      e.target.value = e.target.value.replace(/^\./, '')
      return e
   }
}
const addMaskCel = (item) => {
   if (typeof item === 'string') {
      return item = item.replace(/^([0-9]{2})([0-9]{5})([0-9]{4}).*/, "($1) $2-$3")
   }
}

const addMaskTel = (item) => {
   if (typeof item === 'string') {
      return item = item.replace(/^([0-9]{2})([0-9]{4})([0-9]{4}).*/, "($1) $2-$3")
   }
}
const addMaskCPF = (item) => {
   if (typeof item === 'string') {
      return item = item.replace(/^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2}).*/, "$1.$2.$3-$4")
   }
}
const addMaskCNPJ = (item) => {
   if (typeof item === 'string') {
      return item = item.replace(/^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2}).*/, "$1.$2.$3/$4-$5")
   }
}
const addMaskCEP = (item) => {
   if (typeof item === 'string') {
      return item = item.replace(/^([0-9]{5})([0-9]{3}).*/, "$1-$2")
   }
}
const addMaskDate = (item) => {
   if (typeof item === 'string') {
      return item = item.replace(/^([0-9]{2})([0-9]{2})([0-9]{4})/, "$1/$2/$3")
   }
}
const addMaskMoney = (item) => {
   if (typeof item === 'string') {
      return item = item.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
   }
}

const addMask = (item) => {
   if (typeof item === 'string' && item.length <= 11) {
      return item = item.replace(/^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2}).*/, "$1.$2.$3-$4")
   }
   if (typeof item === 'string' && item.length > 11) {
      if (typeof item === 'string') {
         return item = item.replace(/^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2}).*/, "$1.$2.$3/$4-$5")
      }
   }
}

const testaCPF = (strCPF) => {
   let Soma;
   let Resto;
   Soma = 0;
   if (strCPF.length != 11 ||
      strCPF == "00000000000" ||
      strCPF == "11111111111" ||
      strCPF == "22222222222" ||
      strCPF == "33333333333" ||
      strCPF == "44444444444" ||
      strCPF == "55555555555" ||
      strCPF == "66666666666" ||
      strCPF == "77777777777" ||
      strCPF == "88888888888" ||
      strCPF == "99999999999")
      return false;
   if (strCPF == "00000000000") return false;

   for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
   Resto = (Soma * 10) % 11;

   if ((Resto == 10) || (Resto == 11)) Resto = 0;
   if (Resto != parseInt(strCPF.substring(9, 10))) return false;

   Soma = 0;
   for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
   Resto = (Soma * 10) % 11;

   if ((Resto == 10) || (Resto == 11)) Resto = 0;
   if (Resto != parseInt(strCPF.substring(10, 11))) return false;
   return true;
}

const testaCNPJ = (cnpj) => {

   cnpj = cnpj.replace(/[^\d]+/g, '');

   if (cnpj == '') return false;

   if (cnpj.length != 14)
      return false;

   if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return false;

   tamanho = cnpj.length - 2
   numeros = cnpj.substring(0, tamanho);
   digitos = cnpj.substring(tamanho);
   soma = 0;
   pos = tamanho - 7;
   for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
         pos = 9;
   }
   resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
   if (resultado != digitos.charAt(0))
      return false;

   tamanho = tamanho + 1;
   numeros = cnpj.substring(0, tamanho);
   soma = 0;
   pos = tamanho - 7;
   for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
         pos = 9;
   }
   resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
   if (resultado != digitos.charAt(1))
      return false;

   return true;

}

let mudaURL = (url) => {
   return url.replace('.html', '').split('-').reverse().join('-')
}

let capitalize = s => {
   return s.split(' ').map(item => item.toLowerCase().charAt(0).toUpperCase() + item.toLowerCase().slice(1)).join(' ')
}

let reverseName = (n) => {
   return n.split(' ').reverse().join(' ')
}

let cleanURL = (u) => {
   return u.replace('.html', '')
      .replace(/-/g, ' ')
}

let slugURL = (u) => {
   u = u
      .trim()
      .replace(/\//g, '')
      .replace(/ /g, '-')
      .replace(/,/g, '-')
   let url = removeAcentos(u)
   return url;
}

let addAcentos = (text) => {
   if (typeof (text) == 'string') {
      text = text.toLowerCase();
      text = text.replace(new RegExp('a', 'gi'), '[AÁÀÂÃ]');
      text = text.replace(new RegExp('e', 'gi'), '[EÉÈÊ]');
      text = text.replace(new RegExp('i', 'gi'), '[IÍÌÎ]');
      text = text.replace(new RegExp('o', 'gi'), '[OÓÒÔÕ]');
      text = text.replace(new RegExp('u', 'gi'), '[UÚÙÛ]');
      text = text.replace(new RegExp('ç', 'gi'), '[CÇ]');
      text = text.replace(new RegExp('c', 'gi'), '[CÇ]');
      return text;
   } else {
      return text;
   }
}

let sortByAlphabetical = (array, sortField) => {
   return array.sort(function (a, b) {
      if (a[sortField] < b[sortField]) {
         return -1;
      }
      if (a[sortField] > b[sortField]) {
         return 1;
      }
      return 0;
   })

}

let getIsMobile = (req) => {
   return /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(req ? req.headers['user-agent'] : navigator.userAgent)
}

module.exports = {
   getIsMobile,
   addMaskCel,
   removeMaskNumber,
   addMaskTel,
   addMaskCPF,
   addMask,
   addMaskCNPJ,
   addMaskDate,
   addMaskMoney,
   testaCPF,
   testaCNPJ,
   addMaskCEP,
   mudaURL,
   capitalize,
   reverseName,
   cleanURL,
   slugURL,
   addAcentos,
   changeURL,
   receiveURL,
   receiveUrlList,
   sortByAlphabetical,
}
//console.log(changeURL('curso-online/moda-e-beleza/manicure-e-pedicure'))
