import React, { Component } from 'react';

class ItemCarrinho extends Component {
    constructor(props){
        super(props)
    }
    changeQty = (e) => {
        if(!/[^0-9]/g.test(e.target.value)){
            this.props.updateItem( this.props.index, parseInt(e.target.value))
            if(e.target.name === "qty" && e.target.value > this.props.produto.estoque_site){
                this.props.updateItem(this.props.index, parseInt(this.props.produto.estoque_site))
            }
        }
    }
    retiraProduto = () => {
        this.props.removeItem(this.props.index)
    }
    render(){
        let {produto} = this.props;
        return(
            <table className="table">
                <tbody>
                    <tr>
                        <th width="84px" scope="row"><img className="miniFoto" src={produto.imagem} alt={produto.nome} loading="lazy"/></th>
                        <td>
                            <h6>{produto.nome}</h6>
                            <div>
                                <form className="form-inline">
                                    <div className="form-group col-lg pl-0">
                                        <input
                                            type="text"
                                            name="qty"
                                            className="form-control mr-3"
                                            style={{ marginLeft: '0px' }}
                                            size="5"
                                            value={produto.qty} 
                                            placeholder="1"
                                            onChange={e => this.changeQty(e)} 
                                        />
                                        { produto.estoque_site === 1 &&
                                        (<small className="text-muted">Último disponível!</small>)
                                        }
                                        { produto.estoque_site >= 100 &&
                                        (<small className="text-muted">+ de 100 disponíveis!</small>)
                                        }
                                        { produto.estoque_site > 1 && produto.estoque_site < 100 &&
                                        (<small  className="text-muted">{`(${produto.estoque_site} disponíveis)`}</small>)
                                        }
                                    </div>
                                </form>
                                <div className="total" >R$ {produto.total.toLocaleString('pt-BR', {minimumFractionDigits:2, maximumFractionDigits:2})}</div>
                            </div>
                        </td>
                        <td className="text-right">
                            <p><a className="corRetiraProduto" href="#"><i onClick={this.retiraProduto} className="fa fa-trash retiraProduto" aria-hidden="true"></i></a></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            )
    }
}

export default ItemCarrinho;
