const JsBody = () => (
   <> 
      <script src="/static/js/jquery-3.3.1.slim.min.js"></script>
      <script defer src="/static/js/bc0e8e62f6.js"></script>
      <script defer src="/static/js/popper.min.js"></script>
      {/*<link rel="stylesheet" href="/static/css/all.min.css"/>*/}
   </>
)

export default JsBody;
